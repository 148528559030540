.ReactModal__Overlay {
    transition: transform 300ms ease-in-out;
    transition-delay: 100ms;
    transform: scale(0);
    background-color: rgba(0,0,0,0.5);
}

.ReactModal__Overlay--after-open{
    transform: scale(1);
}

.ReactModal__Overlay--before-close{
    transform: scale(0);
}

.Toastify__toast {
    border-radius: .5rem!important;
}